import React from "react";

const About = () => {
    return (
        <>
            <div className="breadcrumb-area bg-cover shadow dark text-center text-light" style={{ backgroundImage: "url(assets/img/banner/10.jpg" }}>
                <div className="breadcrum-shape">
                    <img src="assets/img/shape/50.png" alt="Image Not Found" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1>About Us</h1>
                            <ul className="breadcrumb">
                                <li><a href="#"><i className="fas fa-home"></i> Home</a></li>
                                <li>About</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-style-one-area default-padding">
                <div className="shape-animated-left">
                    <img src="assets/img/shape/anim-1.png" alt="Image Not Found" />
                    <img src="assets/img/shape/anim-2.png" alt="Image Not Found" />
                </div>
                <div className="container">
                    <div className="row align-center">
                        <div className="about-style-one col-xl-6 col-lg-5">
                            <div className="h4 sub-heading">Feel Valued & Rewarded</div>
                            <h2 className="title mb-25">Information</h2>
                            <p>
                                A pioneer in the transportation industry with a strong legacy the Maruti Mobility solutions LLP offers great services in the field of staff transportation.
                                Founded by the great visionary Mr. Anup B Parikh who in order to pay back to his own motherland partnered with the prestigious Maruti to carve a gem named MMS-LLP.
                                The firm caters to provides mobility solutions using the latest technology equipped vehicles.
                                We assure not only safe and sound transportation but also provides time bound services leading to a hassle free experience.
                                With an enriched experience of our former enterprise and indomitable trust in  Maruti we offer quality services to our clients
                            </p>
                            <div className="owner-info">
                                <div >
                                    <h4>Anup Parikh</h4>
                                    <span>CEO & Founder</span>
                                </div>

                            </div>
                        </div>
                        <div className="about-style-one col-xl-5 offset-xl-1 col-lg-6 offset-lg-1">
                            <div className="about-thumb">
                                <img className="wow fadeInRight" src="assets/img/about/me.jpg" alt="Image Not Found" />
                                <div className="about-card wow fadeInUp" data-wow-delay="500ms">
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <i className="flaticon-license"></i>
                                            </div>
                                            <div className="fun-fact">
                                                <div className="counter">
                                                    <div className="timer" data-to="98" data-speed="2000">98</div>
                                                    <div className="operator">%</div>
                                                </div>
                                                <span className="medium">Delivering Excellence</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="flaticon-global"></i>
                                            </div>
                                            <div className="fun-fact">
                                                <div className="counter">
                                                    <div className="timer" data-to="50" data-speed="2000">50</div>
                                                    <div className="operator">+</div>
                                                </div>
                                                <span className="medium">Pan India Clients</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="thumb-shape-bottom wow fadeInDown" data-wow-delay="300ms">
                                    <img src="assets/img/shape/anim-3.png" alt="Image Not Found" />
                                    <img src="assets/img/shape/anim-4.png" alt="Image Not Found" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-style-one-area default-padding bg-gray">
                <div className="container">
                    <div className="row align-center">
                        <div>
                            <h3 className="sub-title">What we do</h3>
                            <h4 >Ensuring safety in a transportation company involves a combination of factors and measures that collectively minimize risks and promote a secure environment for passengers, drivers, and employees. Here are some key factors that contribute to safety in a transportation company.
                            </h4>
                        </div>
                        <div className="col-lg-12 pl-50 mt-60 pl-md-15 pl-xs-15 text-center">
                            <div className="tab-content services-tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-id-1">
                                    <div className="row services-more">
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="fas fa-file-certificate"></i>
                                                <h4><a href="#">Driver Training and Qualification</a></h4>
                                                <p>
                                                Ensuring that drivers are properly trained, licensed, and regularly assessed on their driving skills and knowledge of safety protocols, while also ensuring they wear proper uniforms and carry ID cards at all times
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="fa fa-wrench"></i>
                                                <h4 ><a href="#">Vehicle Maintenance</a></h4>
                                                <p>
                                                Implementing a rigorous schedule for vehicle maintenance, inspections, PUC checks, permits, and insurance to ensure all vehicles are in optimal working condition and fully compliant with safety standards.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="fa fa-shield"></i>
                                                <h4><a href="#">Safety Equipment</a></h4>
                                                <p>
                                                    Equipping vehicles with necessary safety features such as seat belts, airbags, emergency exits, fire extinguishers, first aid kits, and ensuring they are in good working order.

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="fa fa-gavel"></i>
                                                <h4><a href="#">Compliance with Regulations</a></h4>
                                                <p>
                                                    Adhering to local, state, and federal regulations governing transportation safety, including vehicle safety standards, driver rest periods, and speed limits.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="flaticon-personal"></i>
                                                <h4><a href="#">Safety Policies and Procedures</a></h4>
                                                <p>
                                                    Establishing clear safety policies and procedures that govern driver conduct, passenger behavior, emergency response protocols, and incident reporting.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="fas fa-laptop-house"></i>
                                                <h4><a href="#">Monitoring and Supervision</a></h4>
                                                <p>
                                                    Implementing systems for monitoring driver behavior, vehicle location (through GPS tracking), and operational performance to identify and address potential safety issues proactively.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="fas fa-briefcase-medical"></i>
                                                <h4><a href="#">Emergency Prepareness</a></h4>
                                                <p>
                                                Conducting regular drills and training sessions for drivers and staff on how to respond to emergencies such as accidents, medical incidents, natural disasters, and SOS situations, ensuring preparedness in all scenarios.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="fas fa-chalkboard-teacher"></i>
                                                <h4><a href="#">Communication and Education</a></h4>
                                                <p>
                                                    Providing ongoing safety education and communication to drivers and employees about best practices, new regulations, and safety innovations.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item">
                                                <i className="fa fa-shield-alt"></i>
                                                <h4><a href="#">Safety Culture</a></h4>
                                                <p>
                                                    Promoting a safety-first culture within the company where all employees
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;