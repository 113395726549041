import React, { useState, useRef } from 'react';

const Modal = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        km: '',
        fuel: 'Petrol',
        passing: 'Private',
        city: ''
    });

    const modalRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        fetch("https://script.google.com/macros/s/AKfycbyCCnKC8TvBlJzJHr3hfryiG6-TElURX2BRQ1nCOpdeVhK56Mh9JZuFZbKdcd6QlMnd/exec", {
            method: 'POST',
            body: formData,
        }).then(res => {
            setLoading(false);
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();
        }).then(data => {
            setLoading(false);
            alert("Your Inquiry has been sent successfully");
            const modalElement = modalRef.current;
            const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
            modalInstance.hide();

        }).catch(err => {
            setLoading(false);
            console.error('There was a problem with the fetch operation:', err);
            alert('Submission failed. Please try again.');
        });
    };



    return (
        <div className="modal fade" id="carDetailsModal" tabIndex="-1" aria-labelledby="carDetailsModalLabel" aria-hidden="true" ref={modalRef}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="carDetailsModalLabel">Car Details Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            {/* Name */}
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>

                            {/* Phone Number */}
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Phone Number</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                    pattern="[0-9]{10}"
                                    placeholder="Enter 10 digit phone number"
                                />
                            </div>

                            {/* Kilometers */}
                            <div className="mb-3">
                                <label htmlFor="kilometers" className="form-label">Kilometers</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="km"
                                    name="km"
                                    placeholder="Enter your car KM"
                                    value={formData.km}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            {/* Car Fuel Type */}
                            <div className="mb-3">
                                <label htmlFor="fuelType" className="form-label">Car Fuel Type</label>
                                <select
                                    className="form-select"
                                    id="fuel"
                                    name="fuel"
                                    value={formData.fuel}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="Petrol">Petrol</option>
                                    <option value="Diesel">Diesel</option>
                                    <option value="CNG">CNG</option>
                                </select>
                            </div>

                            {/* Car Passing Type */}
                            <div className="mb-3">
                                <label htmlFor="passingType" className="form-label">Car Passing Type</label>
                                <select
                                    className="form-select"
                                    id="passing"
                                    name="passing"
                                    value={formData.passing}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="Private">Private</option>
                                    <option value="Taxi">Taxi</option>
                                </select>
                            </div>

                            {/* City */}
                            <div className="mb-3">
                                <label htmlFor="city" className="form-label">City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button disabled={loading} type="submit" className="btn btn-primary d-flex align-items-center gap-3">Submit
                                    {loading && <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status" style={{height:"1rem",width:"1rem"}}>
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Modal;