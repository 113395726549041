import React from "react";

const Home = () => {
    return (
        <>
            <div className="banner-area banner-style-one shadow navigation-custom-large zoom-effect overflow-hidden text-light" style={{ height: "94vh" }}>
                <div className="banner-fade">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide banner-style-one">
                            <div className="banner-thumb bg-cover shadow dark" style={{ backgroundImage: "url('assets/img/banner/1.jpg')" }}></div>
                            <div className="container">
                                <div className="row align-center">
                                    <div className="col-xl-7 offset-xl-5">
                                        <div className="content">
                                            <h4>Coordinating Transit Solutions</h4>
                                            <h2><strong> Organizing Transportation</strong> Schedules</h2>
                                            <div className="button mt-40">
                                                <a className="btn-animation" href="/services"><i className="fas fa-arrow-right"></i> <span>Our
                                                    Services</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="banner-shape-bg">
                                <img src="assets/img/shape/4.png" alt="Shape" />
                            </div> */}
                        </div>
                        <div className="swiper-slide banner-style-one">
                            <div className="banner-thumb bg-cover shadow dark" style={{ backgroundImage: "url('assets/img/banner/6.jpg')" }}></div>
                            <div className="container">
                                <div className="row align-center">
                                    <div className="col-xl-7 offset-xl-5">
                                        <div className="content">
                                            <h4>Cab Operations Guidance & Support</h4>
                                            <h2><strong>Strategies for</strong> Enduring Success</h2>
                                            <div className="button mt-40">
                                                <a className="btn-animation" href="/services"><i className="fas fa-arrow-right"></i> <span>Our
                                                    Services</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="banner-shape-bg">
                                <img src="assets/img/shape/4.png" alt="Shape" />
                            </div> */}
                        </div>
                    </div>
                    <div className="swiper-pagination"></div>

                </div>
            </div>

            <div className="about-style-one-area default-padding">
                <div className="shape-animated-left">
                    <img src="assets/img/shape/anim-1.png" alt="Image Not Found" />
                    <img src="assets/img/shape/anim-2.png" alt="Image Not Found" />
                </div>
                <div className="container">
                    <div className="row align-center">
                        <div className="about-style-one col-xl-6 col-lg-5">
                            <div className="h4 sub-heading">Core Values</div>
                            <h2 className="title mb-25"> Integrity and Excellence</h2>
                            <p>
                                MMS-LLP Connect aims at pursuing modern technologies to facilitate its customers, always ready to revamp its methods of operation. The principles on which the company works are remarkable and non-compromised. We are always ready to serve the best to our clients. Honesty leads the company’s core values, and responsibility towards the customers is our forte. Our association with the renowned brand Maruti has added more prestige and accountability to our services. We maintain a strict zero-tolerance policy towards sexual harassment, ensuring a safe and respectful environment for all employees and customers. Additionally, we emphasize punctuality, delivering timely services to avoid any inconvenience. Our commitment to safety is reflected in our strict stance against drinking and driving. Staff transportation is a humongous responsibility, which we aspire to fulfill smoothly and effectively under any given circumstances.
                            </p>
                        </div>
                        <div className="about-style-one col-xl-5 offset-xl-1 col-lg-6 offset-lg-1">
                            <div className="about-thumb">
                                <img className="wow fadeInRight" src="assets/img/about/7.png" alt="Image Not Found" />
                                <div className="thumb-shape-bottom wow fadeInDown" data-wow-delay="300ms">
                                    <img src="assets/img/shape/anim-3.png" alt="Image Not Found" />
                                    <img src="assets/img/shape/anim-4.png" alt="Image Not Found" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="process-style-one-area text-center default-padding">
                <div className="large-shape">
                    <img src="assets/img/shape/11.png" alt="Shape" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="site-heading text-center">
                                <h4 className="sub-heading">Our Process</h4>
                                <h2 className="title">Steps of Recruitment <br /> work process</h2>
                                <div className="devider"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="process-style-one active">
                                <div className="thumb">
                                    <img src="assets/img/about/3.jpg" alt="Thumb" />
                                    <span>01</span>
                                </div>
                                <h4>Differentiate from the competition</h4>
                                <p>
                                    Trained professionals hired through a systematic selection procedure.
                                    Compliance towards rules and regulations of the company.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="process-style-one">
                                <div className="thumb">
                                    <img src="assets/img/about/6.jpg" alt="Thumb" />
                                    <span>02</span>
                                </div>
                                <h4>Target the right people effectively</h4>
                                <p>
                                    Well behaved and sincere towards their  duty.
                                    Experienced staff dedicated towards safety and security of the customers.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="process-style-one">
                                <div className="thumb">
                                    <img src="assets/img/about/5.jpg" alt="Thumb" />
                                    <span>03</span>
                                </div>
                                <h4>Communicate your story consistently</h4>
                                <p>
                                    Training pertaining to sexual harassment provided as the top notch priority.
                                    Incentives programme for performace.

                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="choose-us-style-one-area default-padding text-light">
                <div className="cover-bg" style={{ backgroundImage: "url('assets/img/banner/7.jpg')" }}></div>
                <div className="shape-left-top">
                    <img src="assets/img/shape/17.png" alt="Shape" />
                </div>
                <div className="text-invisible">MMS-LLP</div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-80">
                            <div className="choose-us-style-one">
                                <h2 className="title mb-35">Leading the Way in Transportation Innovation</h2>
                                <ul className="list-item">
                                    <li className="wow fadeInUp">
                                        <h4>Premier Transportation Service </h4>
                                        <p>
                                            Expert advice for optimizing fleet management and enhancing operational efficiency. We provide top-tier solutions for seamless transportation, offering a diverse fleet that includes SUVs, luxury SUVs, sedans, premium sedans, and tempo travelers, all backed by our expert guidance to boost operational efficiency.
                                        </p>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay="300ms">
                                        <h4>24/7 Supervision </h4>
                                        <p>
                                            Our dedicated team is available around the clock to ensure every ride is smooth and hassle-free. Each scheduled vehicle is assigned a dedicated supervisor, providing 24/7 oversight to keep your transportation safe and secure at all times
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="partner-style-one-area default-padding">
                <div className="container">
                    <div className="row align-center">
                        <div className="col-lg-5">
                            <div className="partner-map" style={{ backgroundImage: "url('assets/img/shape/map.png')" }}>
                                <h2 className="mask-text" style={{ backgroundImage: "url('assets/img/banner/10.jpg')" }}>40</h2>
                                <h4>clients</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="partner-items">
                                <ul>
                                    <li><img src="assets/img/logo/1.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/2.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/3.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/4.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/5.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/6.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/10.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/11.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/12.png" alt="Image Not FOund" /></li>
                                    <li><img src="assets/img/logo/13.png" alt="Image Not FOund" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="project-style-one-area default-padding bg-dark bottom-shape-light">
                <div className="container">
                    <div className="heading-left text-light">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="content-left">
                                    <h5 className="sub-heading">Popular Projects</h5>
                                    <h2 className="heading">Our most recent Completed Projects</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="project-style-one-carousel swiper">
                                <div className="swiper-wrapper">

                                    <div className="swiper-slide">
                                        <div className="project-style-one">
                                            <div className="row align-bottom">
                                                <div className="col-lg-7 pr-0 pr-md-15 pr-xs-15 pl-md-15 pl-xs-15">
                                                    <div className="thumb">
                                                        <img src="assets/img/projects/1.jpg" alt="Image Not Found" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 pl-0 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15">
                                                    <div className="info">
                                                        <span>Strategy</span>
                                                        <h3><a href="#">Digital business planning</a></h3>
                                                        <p>
                                                            Excellence projecting is devonshire dispatched remarkably on estimating. Side in so life past.
                                                            Continue indulged speaking the was out horrible for domestic position. Seeing rather her you not
                                                            esteem men settle genius excuse. Deal say over you age from. Comparison new ham melancholy son.
                                                        </p>
                                                        <a className="btn-animation dark mt-10" href="#"><i className="fas fa-arrow-right"></i> <span>View
                                                            Project</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="project-style-one">
                                            <div className="row align-bottom">
                                                <div className="col-lg-7 pr-0 pr-md-15 pr-xs-15 pl-md-15 pl-xs-15">
                                                    <div className="thumb">
                                                        <img src="assets/img/projects/2.jpg" alt="Image Not FOund" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 pl-0 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15">
                                                    <div className="info">
                                                        <span>Partnership</span>
                                                        <h3><a href="#">Business program management</a></h3>
                                                        <p>
                                                            Excellence projecting is devonshire dispatched remarkably on estimating. Side in so life past.
                                                            Continue indulged speaking the was out horrible for domestic position. Seeing rather her you not
                                                            esteem men settle genius excuse. Deal say over you age from. Comparison new ham melancholy son.
                                                        </p>
                                                        <a className="btn-animation dark mt-10" href="#"><i className="fas fa-arrow-right"></i> <span>View
                                                            Project</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="project-swiper-nav">

                                    <div className="project-pagination"></div>

                                    <div className="project-button-prev"></div>
                                    <div className="project-button-next"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="request-call-back-area text-light default-padding"
                style={{ backgroundImage: "url('assets/img/banner/13.jpg')" }}>
                <div className="container">
                    <div className="row align-center">
                        <div className="col-lg-6">
                            <h2 className="title">Looking for a First-Class <br /> Transportation Service?</h2>
                            <a className="btn circle btn-light mt-30 mt-md-15 mt-xs-10 btn-md radius animation" href="/contact-us">Request a Call
                                back</a>
                        </div>
                        <div className="col-lg-6 text-end">
                            <div className="achivement-counter">
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <i className="flaticon-handshake"></i>
                                        </div>
                                        <div className="fun-fact">
                                            <div className="counter">
                                                <div className="timer" data-to="500" data-speed="2000">10M</div>
                                                <div className="operator">+</div>
                                            </div>
                                            <span className="medium">Successful Transportation services Provided Over 20 Years</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="flaticon-employee"></i>
                                        </div>
                                        <div className="fun-fact">
                                            <div className="counter">
                                                <div className="timer" data-to="30" data-speed="2000">30</div>
                                                <div className="operator">+</div>
                                            </div>
                                            <span className="medium">Awards for Excellence in Service and Reliability</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="testimonial-style-one-area default-padding">
                <div className="container">
                    <div className="row align-center">

                        <div className="col-lg-4">
                            <div className="testimonial-thumb">
                                <div className="thumb-item">
                                    <img src="assets/img/illustration/5.png" alt="illustration" />
                                    <div className="mini-shape">
                                        <img src="assets/img/shape/19.png" alt="illustration" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 offset-lg-1">
                            <div className="testimonial-carousel swiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="testimonial-style-one">

                                            <div className="item">
                                                <div className="content">
                                                    <div className="rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    <h2>The best service ever</h2>
                                                    <p>
                                                        “Targetingconsultation discover apartments. ndulgence off under folly death wrote cause her way
                                                        spite. Plan upon yet way get cold spot its week. Almost do am or limits hearts. Resolve parties
                                                        but why she shewing. She sang know now always remembering to the point.”
                                                    </p>
                                                </div>
                                                <div className="provider">
                                                    <i className="flaticon-quote"></i>
                                                    <div className="info">
                                                        <h4>Matthew J. Wyman</h4>
                                                        <span>Senior Consultant</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="swiper-slide">
                                        <div className="testimonial-style-one">
                                            <div className="item">
                                                <div className="content">
                                                    <div className="rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    <h2>Awesome Business opportunities</h2>
                                                    <p>
                                                        “Consultation discover apartments. ndulgence off under folly death wrote cause her way spite. Plan
                                                        upon yet way get cold spot its week. Almost do am or limits hearts. Resolve parties but why she
                                                        shewing. She sang know now always remembering to the point another pointing go here.”
                                                    </p>
                                                </div>
                                                <div className="provider">
                                                    <i className="flaticon-quote"></i>
                                                    <div className="info">
                                                        <h4>Anthom Bu Spar</h4>
                                                        <span>Marketing Manager</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Home;