import React from "react";

const Services = () => {
    return (
        <>
            <div className="breadcrumb-area bg-cover shadow dark text-center text-light" style={{ backgroundImage: "url(assets/img/banner/10.jpg" }}>
                <div className="breadcrum-shape">
                    <img src="assets/img/shape/50.png" alt="Image Not Found" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1>Service Details</h1>
                            <ul className="breadcrumb">
                                <li><a href="/"><i className="fas fa-home"></i> Home</a></li>
                                <li>Service</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="project-details-area default-padding bg-gray">
                <div className="center-shape" style={{ background: "url(assets/img/shape/5.png)" }}></div>
                <div className="container">
                    <div className="project-details-items">
                        <div className="top-info">
                            <div className="row">
                                <div className="col-xl-12 col-lg-7 pr-35 pr-md-15 pr-xs-15 left-info mt-md-10 ">
                                    <h2>Our Services</h2>
                                    <p>
                                        Each service provides distinct advantages, catering to various transportation needs, from fixed routes for daily commutes to customizable options like hiring a cab or arranging individual pickups.
                                    </p>
                                    <ul className="check-list">
                                        <li>
                                            <h4 className="sub-title">Staff Transportation</h4>
                                            <p>
                                                Depicted with a bus icon, this service is tailored specifically for staff, ensuring that employees are transported reliably and efficiently to and from their workplaces.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="sub-title">Fixed Route</h4>
                                            <p>
                                                Illustrated with a route sign displaying "Infocity" and "S.G," this service follows predetermined routes, making it ideal for regular commutes between fixed locations like offices or business parks.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="sub-title">Hire a Cab</h4>
                                            <p>
                                                Shown with a cab icon, this option allows customers to hire private cabs for transportation, offering flexibility and privacy for travel needs
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="sub-title">Shuttle Services</h4>
                                            <p>
                                                Marked with a bus icon, these services are for group transport, likely with multiple stops or routes, convenient for employees, students, or event attendees
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="sub-title">Pick Up & Drop</h4>
                                            <p>
                                                Represented by a person greeting a car, this service offers scheduled pickups and drop-offs, making it ideal for personalized transportation requirements such as airport transfers, railway stations or client services.
                                            </p>
                                        </li>
                                        <li>
                                            <h4 className="sub-title">on Demand Vehicle</h4>
                                            <p>
                                            Experience quick and reliable on-demand cab service at your fingertips. Wherever you need to go, we’ll get you there safely and on time.
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                        <div className="main-content">

                            <p>
                                MMS-LLP is a comprehensive management and turnkey transportation operations provider. Our scale and specialized approach enable us to make quick, informed decisions, always aiming for exceptional service. We take full responsibility and accountability for the quality of our operations. Our services often require us to offer or support the programs and solutions.
                                We assist clients in preparing or managing rosters or schedules, saving them time and ensuring efficiency.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services;