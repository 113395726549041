import React from "react";

const Footer = () => {
    return (
        <footer className="bg-dark text-light">
            <div className="container">
                <div className="f-items relative pt-70 pb-120 pt-xs-0 pb-xs-50">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 footer-item pr-50 pr-xs-15">
                            <div className="f-item about">
                                <img className="logo img-logo" src="assets/img/logo.png" alt="Logo" />
                                <p>
                                    Our transportation service delivers exceptional quality, standing out with maximum efficiency and leaving a lasting impression. The experience is nothing short of perfect. Authorized Vendor on Government e-Marketplace (GeM).
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 footer-item">
                            <div className="f-item link">
                                <h4 className="widget-title">Quick Links</h4>
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="/about-us">About Us</a>
                                    </li>
                                    <li>
                                        <a href="/services">Services</a>
                                    </li>
                                    <li>
                                        <a href="/contact-us">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-item">
                            <h4 className="widget-title">Social Media</h4>
                            {/* <p>
                                Join our subscribers list to get the latest <br /> news and special offers.
                            </p>
                            <div className="f-item newsletter">
                                <form action="#">
                                    <input type="email" placeholder="Your Email" className="form-control" name="email" />
                                    <button type="submit"> Subscribe</button>
                                </form>
                            </div> */}
                            <ul className="footer-social">
                                <li>
                                    <a href="#">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-item">
                            <h4 className="widget-title">Have a Questions?</h4>
                            <ul className="footer-social">
                                <li>
                                    <div className="d-flex align-items-baseline " >
                                        <i className="fa fa-home"></i>&nbsp;&nbsp;
                                        <p>211, Super Mall-2, Info city, Gandhinagar, Gujarat,<br /> 380009, India
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-baseline " >
                                        <i className="fa fa-phone"></i>&nbsp;&nbsp;
                                        <p>+91 90990 73330
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-baseline " >
                                        <i className="fa fa-envelope"></i>&nbsp;&nbsp;
                                        <p>info@marutims.com
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 center"></div>
                        <div className="col-lg-4 center">
                            <p>&copy; Copyright 2024. All Rights Reserved by <a href="#">MMS</a></p>
                        </div>
                    </div>
                    <div className="col-lg-4 center"></div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;