import React, { useRef, useState } from "react";

function App() {
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        fetch("https://script.google.com/macros/s/AKfycbyCCnKC8TvBlJzJHr3hfryiG6-TElURX2BRQ1nCOpdeVhK56Mh9JZuFZbKdcd6QlMnd/exec", {
            method: 'POST',
            body: new FormData(formRef.current),
        }).then(res => {
            setLoading(false);
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();
        }).then(data => {
            setLoading(false);
            alert("Your Inquiry has been sent successfully");
        }).catch(err => {
            setLoading(false);
            console.error('There was a problem with the fetch operation:', err);
            alert('Submission failed. Please try again.');
        });
    };

    return (
        <>
            <div className="breadcrumb-area bg-cover shadow dark text-center text-light" style={{ backgroundImage: "url(assets/img/banner/10.jpg" }}>
                <div className="breadcrum-shape">
                    <img src="assets/img/shape/50.png" alt="Image Not Found" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1>Contact Us</h1>
                            <ul className="breadcrumb">
                                <li><a href="#"><i className="fas fa-home"></i> Home</a></li>
                                <li>Contact</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-style-one-area overflow-hidden default-padding">

                <div className="contact-shape">
                    <img src="assets/img/shape/37.png" alt="Image Not Found" />
                </div>

                <div className="container">
                    <div className="row align-center">

                        <div className="contact-stye-one col-lg-5 mb-md-50 mb-xs-20">

                            <div className="contact-style-one-info">
                                <h2>Contact Information</h2>
                                <p>
                                    Let me know if you'd like any further adjustments
                                </p>
                                <ul>
                                    <li className="wow fadeInUp">
                                        <div className="icon">
                                            <i className="fas fa-phone-alt"></i>
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Hotline</h5>
                                            <a href="tel:07940029911">079-40029911</a>
                                        </div>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay="300ms">
                                        <div className="icon">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div className="info">
                                            <h5 className="title">Our Location</h5>
                                            <p>
                                                211, Super Mall-2, Infocity, Gandhinagar,<br />Gujarat, 380009, India
                                            </p>
                                        </div>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay="500ms">
                                        <div className="icon">
                                            <i className="fas fa-envelope-open-text"></i>
                                        </div>
                                        <div className="info">
                                            <h5 className="title">Official Email</h5>
                                            <a href="mailto:info@marutims.com">info@marutims.com</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="contact-stye-one col-lg-7 pl-60 pl-md-15 pl-xs-15">
                            <div className="contact-form-style-one">
                                <h5 className="sub-title">Have Questions?</h5>
                                <h2 className="heading">Send us a Massage</h2>
                                <form id="form" ref={formRef} onSubmit={handleSubmit} >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input className="form-control" required id="name" name="name" placeholder="Name" type="text" />
                                                <span className="alert-error"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input className="form-control" required id="email" name="email" placeholder="Email*" type="email" />
                                                <span className="alert-error"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input className="form-control" required id="phone" name="phone" placeholder="Phone" type="text" />
                                                <span className="alert-error"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group comments">
                                                <textarea className="form-control" required id="comments" name="comments" placeholder="Tell Us About Requirement *"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <button disabled={loading} type="submit" className="d-flex align-items-center gap-3" name="submit" id="submit">
                                                <i className="fa fa-paper-plane"></i> Get in Touch
                                                {loading && <div class="d-flex justify-content-center">
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

            <div className="maps-area bg-gray overflow-hidden">
                <div className="google-maps">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.285308881821!2d72.63135447563504!3d23.19627077905242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2a30ab4b45a5%3A0xa334c578945e6121!2sSuper%20Mall%201!5e0!3m2!1sen!2sin!4v1727167527972!5m2!1sen!2sin"></iframe>
                </div>
            </div>
        </>
    );
}

export default App;
