import React, { useEffect, useState } from "react";
import Modal from './HireModal'
const Header = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000)
    }, [])

    return (
        <>
            <div id="preloader">
                <div id="marutims-preloader" class="marutims-preloader">
                    {loading && <>
                        <div class="animation-preloader">
                            <img src="assets/img/logo.png" className="logo img-logo" width={700} alt="Logo" />
                            <div class="spinner"></div>
                        </div>
                        <div class="loader">
                            <div class="row">
                                <div class="col-3 loader-section section-left">
                                    <div class="bg"></div>
                                </div>
                                <div class="col-3 loader-section section-left">
                                    <div class="bg"></div>
                                </div>
                                <div class="col-3 loader-section section-right">
                                    <div class="bg"></div>
                                </div>
                                <div class="col-3 loader-section section-right">
                                    <div class="bg"></div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
            <div className="top-bar-area top-bar-style-one bg-dark text-light">
                <div className="container">
                    <div className="row align-center">
                        <div className="col-xl-6 col-lg-8 offset-xl-3 pl-30 pl-md-15 pl-xs-15">
                            <ul className="item-flex">
                                <li>
                                    <i className="fas fa-map-marker-alt"></i> 211, Super Mall-2, Infocity, Gandhinagar
                                </li>
                                <li>
                                    <a href="tel:+919099073330"><i className="fas fa-phone-alt"></i>+91-90990 73330</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <header>
                <nav className="navbar mobile-sidenav navbar-style-one navbar-sticky navbar-default validnavs white navbar-fixed no-background">

                    <div className="container">
                        <div className="row align-center">

                            <div className="col-xl-2 col-lg-1 col-md-1 col-sm-1 col-">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                        <i className="fa fa-bars"></i>
                                    </button>
                                    <a className="navbar-brand" href="/">
                                        <img src="assets/img/logo.png" className="logo img-logo" alt="Logo" />
                                    </a>
                                </div>
                            </div>

                            <div className="col-xl-6 offset-xl-1 col-lg-6 col-md-4 col-sm-4 col-4">
                                <div className="collapse navbar-collapse" id="navbar-menu">

                                    <img src="assets/img/logo.png" alt="Logo" />
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                        <i className="fa fa-times"></i>
                                    </button>

                                    <ul className="nav navbar-nav navbar-center" data-in="fadeInDown" data-out="fadeOutUp">
                                        <li >
                                            <a href="/" >Home</a>
                                        </li>
                                        <li><a href="/about-us"  >About us</a></li>
                                        <li >
                                            <a href="/services"  >Services</a>
                                        </li>
                                        <li><a href="/contact-us">contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-7 col-7">
                                <div className="attr-right">
                                    <div className="attr-nav">
                                        <ul>
                                            <button className="btn btn-primary dancing-button" data-bs-toggle="modal" data-bs-target="#carDetailsModal" >
                                                We Hire
                                            </button>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="overlay-screen"></div>
                    </div>
                </nav>
            </header>
            <Modal/>
        </>
    )
}

export default Header;